import { module } from 'modujs';
import { html } from '../utils/environment';
import PhotoSwipeUI_Default from '../utils/pswp/pswp-ui';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                item: 'open'
            }
        }

        // Declaration of properties
        this.galleryItems = this.$('item')
        this.galleryItems = Array.from(this.galleryItems).map((item, i) => {
            this.setData('index', i, item)

            let [w, h] = this.getData('size',item).split(',')

            return {
                // el: item,
                src: this.getData('fullsize',item),
                msrc: this.getData('src',item),
                w,h,
            }
        });
    }

    init() {

    }

    open(e){
        const el = e.curTarget;
        const index = parseInt(this.getData('index', el));

        const options = {
            index,
            closeOnScroll: false,
            spacing: 0,
            showHideOpacity: true,
            history: false,
            closeEl:true,
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            counterEl: true,
            arrowEl: true,
            preloaderEl: true,
            getThumbBoundsFn: (closingIndex) => {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    const rect = el.getBoundingClientRect();
                    let result = {x:rect.left, y:rect.top, w:rect.width }

                    if(index != closingIndex) {
                        // return false
                        const windowW = window.innerWidth, windowH = window.innerHeight, ratio = this.galleryItems[closingIndex].h / this.galleryItems[closingIndex].w
                        let x = windowW/2, y = windowH/2, w, h

                        if(windowW > windowH) {
                            h = windowH//*0.8
                            w = h * 1/ratio
                        }
                        else {
                            w = windowW//*0.8
                            h = w*ratio
                        }

                        x-=w/2
                        y-=h/2

                        result = { x, y ,w }
                    }

                    if(!html.classList.contains('has-smooth-scroll')) {
                        result.y+=window.pageYOffset || document.documentElement.scrollTop;
                    }

                    return result;
                }
        }

        // Initializes and opens PhotoSwipe
        let gallery = new PhotoSwipe( document.getElementById('pswp'), PhotoSwipeUI_Default, this.galleryItems, options);
        gallery.init();
    }
}
