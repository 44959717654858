import { module } from 'modujs';

const CLASS = {
    OPEN: `has-share-open`
}

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                item: 'share',
                toggler: 'toggle'
            }
        }
    }

    init() {
        this.isOpen = false
    }

    toggle() {
        if(this.isOpen) {
            this.isOpen = !this.isOpen;
            this.el.classList.remove(CLASS.OPEN);
        } else {
            this.isOpen = !this.isOpen;
            this.el.classList.add(CLASS.OPEN);
        }
    }

    share(event) {
        event.preventDefault();

        const el = event.curTarget;

        const platform = el.getAttribute('data-share-platform');
        const url = window.location.href;
        const text = document.title;

        let shareUrl = url;

        switch(platform) {

            case 'facebook':
                shareUrl = 'https://facebook.com/sharer/sharer.php?u=' + url;
                this.openWindow(shareUrl);
                break;

            case 'linkedin':
                shareUrl = 'https://www.linkedin.com/shareArticle?url=' + url;
                this.openWindow(shareUrl);
                break;

            case 'twitter':
                shareUrl = 'https://twitter.com/share?url=' + url
                         + '&amp;text=' + encodeURIComponent(text);

                this.openWindow(shareUrl);
                break;

            case 'mail':
                const subject = text;
                const body = url;

                this.openMail(subject, body);
                break;

            case 'copy':
                this.copyUrl(url, $this);
                break;

        }
    }

    openWindow(url) {
        window.open(url, '', 'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=500, width=600');
    }

    openMail(subject, body) {
        // window.location = 'mailto:?subject=' + subject + '&body=' + body;
        window.location = 'mailto:?body=' + body;
    }


    copyUrl(url) {
        // $html.addClass('has-link-copied')

        // setTimeout(() => {
        //     $html.removeClass('has-link-copied')
        // }, 1500)

        // window.copyToClipboard(url)
    }

    destroy() {
        this.el.classList.remove(CLASS.OPEN);
    }
}
