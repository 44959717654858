import { module } from 'modujs';
import { html } from '../utils/environment'
import { lerp } from '../utils/maths'
import { transform } from '../utils/transform'

const CLASS = {
    ACTIVE: `is-active`
}

export default class extends module {
    constructor(m) {
        super(m);

        if(!window.isMobile){
            this.events = {
                mouseenter: {
                    name: 'changeImg',
                }
            }
        }
    }

    init(){
        this.isHover = false
    }

    changeImg(e)  {
        let el = e.curTarget
        let index = Array.from(el.parentNode.children).indexOf(el)

        this.$('img').forEach((item) => {
            item.classList.remove(CLASS.ACTIVE)
        })


        this.$('placeholder')[0].classList.remove(CLASS.ACTIVE)
        this.$('img')[index].classList.add(CLASS.ACTIVE)
    }
}
