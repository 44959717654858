import { module } from 'modujs';


export default class extends module {
    constructor(m) {
        super(m);
        this.slider = this.$('slider')[0]
        if(!this.slider) this.slider = this.el

        this.arrowNext = this.$('next')[0]
        this.arrowPrev = this.$('prev')[0]
    }

    init() {
        this.length = this.$('slide').length

        const args = {
            speed: 600,
            loop: false,
            spaceBetween: 15,
            grabCursor: true,
            breakpoints: {
                700:{
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1000: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            },
            navigation: {
                nextEl: this.arrowNext,
                prevEl: this.arrowPrev,
            }
        }

        if(this.length > 1) this.carousel = new Swiper(this.slider, args)
    }

    destroy() {
        if(this.carousel && this.carousel.destroy) this.carousel.destroy(true, true)
    }
}
