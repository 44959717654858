import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    OPENTHEMATIC: `has-thematic-overlay-open`,
    OPENOVERLAY: `has-overlay-open`
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open'
            }
        }
    }

    init(){

    }

    open() {
        this.call('close', null, 'MenuToggler')
        html.classList.add(CLASS.OPENTHEMATIC)
        html.classList.add(CLASS.OPENOVERLAY)

        setTimeout(() => {
            this.call('update', null,'Scroll','overlay')
        }, 900)
    }

    close() {
        html.classList.remove(CLASS.OPENTHEMATIC)
        html.classList.remove(CLASS.OPENOVERLAY)
    }
}
