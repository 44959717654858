import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    OPENVIDEO: `has-video-overlay-open`,
    OPENOVERLAY: `has-overlay-open`
}

export default class extends module {
    constructor(m) {
        super(m);
        this.inner = this.$('inner')[0]
    }

    openVideo(e) {
        if(this.emptyTimeout) clearTimeout(this.emptyTimeout);

        this.appendDelay = setTimeout(() => {
            switch(e.host) {
                case 'youtube':
                    this.inner.innerHTML = `<iframe src="https://www.youtube.com/embed/${e.id}?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
                    break;
                case 'vimeo':
                    this.inner.innerHTML = `<iframe src="https://player.vimeo.com/video/${e.id}?autoplay=1&loop=1&autopause=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
                    break;
                default:
                    break;
            }
        }, 500);

        html.classList.add(CLASS.OPENVIDEO)
        html.classList.add(CLASS.OPENOVERLAY)
    }

    close() {
        html.classList.remove(CLASS.OPENVIDEO)
        html.classList.remove(CLASS.OPENOVERLAY)

        clearTimeout(this.appendDelay)

        this.emptyTimeout = setTimeout(() => {
            this.inner.innerHTML='';
        }, 250);
    }
}
