import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.analyticsId = this.getData('analytics');

        this.load = new modularLoad({
            enterDelay: 900,
            transitions: {
                repertoire: {
                    enterDelay: 600
                }
            }
        });

        this.load.on('loading', () => {
            this.call('close', null, 'MenuToggler')
            html.classList.add('is-changing-page')
        });

        this.load.on('loaded', (_transition, oldContainer, newContainer) => {
            window.scrollTo(0,0);

            this.call('close', null, 'RegionToggler')
            this.call('close', null, 'VideoOverlay')
            this.call('close', null, 'ThematicToggler')
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
            html.classList.remove('is-changing-page')
            html.classList.remove('has-header-bg')

            setTimeout(() => {
                html.classList.add('is-animated');
            }, 800);

            if (window.gtag && this.analyticsId != null) {
                gtag('config', this.analyticsId, {
                    'page_path':  location.pathname,
                    'page_title': document.title
                });
            }
        });

        this.load.on('ready', (transition, newContainer) => {

            if(transition === 'repertoire') {
                setTimeout(() => {
                    this.call('scrollTo', { target: newContainer }, 'Scroll', 'main')
                }, 100);
            }
        });
    }

    goTo(obj) {
        const { url, transition } = obj

        this.load?.goTo(url, transition);
    }
}
