import { module } from 'modujs';
import { html } from '../utils/environment';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        setTimeout(() => {
            this.scroll = new LocomotiveScroll({
                el: this.el,
                smooth: (!!window.MSInputMethodContext && !!document.documentMode) ? false : true
            });

            this.scroll.on('scroll', (obj) => {
                window.scrollObj = obj

                if(obj.scroll.y >= 20) {
                    html.classList.add('has-header-bg')
                } else {
                    html.classList.remove('has-header-bg')
                }
            })

            this.scroll.on('call', (func,way,obj,id) => {
                // Using modularJS
                this.call(func[0],{way,obj},func[1],func[2]);
            });
        }, 600)

        setTimeout(() => {
            this.scroll.update();
        }, 1000);
    }

    update(){
        if(this.scroll && this.scroll.update) {
            this.scroll.update();

            if(this.el.getAttribute('data-module-scroll') === 'overlay'){
                this.scrollTo({target:'top'})
            }
        }
    }

    reinit() {
       this.scroll.init()
    }

    scrollTo(obj) {
        this.scroll.scrollTo(obj.target, obj.offset);
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if(src.length) {
            if(args.obj.el.tagName == "IMG") {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url(${src})`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }
}
