import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';

const app = new modular({
    modules: modules
});

window.isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);


setTimeout(() => {
    html.classList.add('has-loading-animation');
},200)

setTimeout(() => {
    app.init(app);
    globals();

    html.classList.add('is-first-loading');
    html.classList.add('is-loaded');
    html.classList.remove('is-loading');

    setTimeout(() => {
        html.classList.add('is-animated');
    }, 800);

    setTimeout(() => {
        html.classList.remove('has-loading-animation');
    }, 800);

},1000)

