import { module } from 'modujs';
import { html } from '../utils/environment'
import { lerp } from '../utils/maths'
import { transform } from '../utils/transform'

const CLASS = {
    ACTIVE: `is-active`
}

export default class extends module {
    constructor(m) {
        super(m);

        if(!window.isMobile){
            this.events = {
                mouseenter: {
                    name: 'changeImg',
                    hover: 'handleMouseEnter'
                },
                mouseleave: {
                    hover: 'handleMouseLeave'
                },
                mousemove: 'manageMouse'
            }
        }
    }
    init(){
        this.cursorPosition = { x: 0, y: 0 }
        this.lerpedPosition = { x: 0, y: 0 }
        this.isHover = false
    }

    changeImg(e)  {
        let el = e.curTarget
        let index = Array.from(el.parentNode.children).indexOf(el)

        this.$('img').forEach((item) => {
            item.classList.remove(CLASS.ACTIVE)
        })

        this.$('img')[index].classList.add(CLASS.ACTIVE)
    }

    handleMouseEnter()  {
        this.isHover = true
        this.$('cursor')[0].classList.add(CLASS.ACTIVE)

        this.update()
    }

    handleMouseLeave()  {
        this.isHover = false
        this.$('cursor')[0].classList.remove(CLASS.ACTIVE)

        cancelAnimationFrame(this.raf)
    }

    manageMouse(e){
        if(!this.isHover) {
            this.lerpedPosition.x = this.cursorPosition.x
            this.lerpedPosition.y = this.cursorPosition.y
        }

        this.cursorPosition.x = e.clientX
        this.cursorPosition.y = e.clientY
    }

    update(){
        if(this.isHover){
            this.raf = requestAnimationFrame(this.update.bind(this));

            this.lerpedPosition.x = lerp(this.lerpedPosition.x, this.cursorPosition.x, 0.1)
            this.lerpedPosition.y = lerp(this.lerpedPosition.y, this.cursorPosition.y, 0.1)

            transform(this.$('cursor')[0], `translate3d(${this.lerpedPosition.x}px,${this.lerpedPosition.y}px,0)`)
        }
    }

    scrollTop(){
        this.$('scroll-container')[0].scrollTop = 0
    }

    destroy(){
        cancelAnimationFrame(this.raf)
    }
}
