import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    OPEN: `has-nav-open`,
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                button: 'open'
            }
        }
    }

    init(){

    }

    open() {
        html.classList.add(CLASS.OPEN)
    }

    close() {
        html.classList.remove(CLASS.OPEN)
    }
}
