import { module } from 'modujs';
import { html } from '../utils/environment'

const CLASS = {
    OPENREGION: `has-region-overlay-open`,
    OPENOVERLAY: `has-overlay-open`
}

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open'
            }
        }
    }

    init(){

    }

    open() {
        this.call('close', null, 'MenuToggler')
        this.call('scrollTop', null, 'RegionOverlay')
        html.classList.add(CLASS.OPENREGION)
        html.classList.add(CLASS.OPENOVERLAY)
    }

    close() {
        html.classList.remove(CLASS.OPENREGION)
        html.classList.remove(CLASS.OPENOVERLAY)
    }
}
