import { module } from 'modujs';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                button: 'close'
            }
        }
    }

    init(){

    }

    close() {
        this.call('close', null, 'RegionToggler')
        this.call('close', null, 'ThematicToggler')
        this.call('close', null, 'MenuToggler')
        this.call('close', null, 'VideoOverlay')
    }
}
