import { module } from 'modujs'

export default class extends module {
    constructor(m) {
        super(m)

        this.$form = this.el

        this.basePath = this.getData('path')

        this.events = {
            submit: 'onSubmit'
        }
    }

    onSubmit(e) {
        e.preventDefault()

        // Retrieve FormData
        const formData = new FormData(this.$form)

        // Clear empty fields from FormData
        for (const entry of Array.from(formData.entries())) {
            if (!entry[1]) formData.delete(entry[0])
        }

        const parametersString = new URLSearchParams(formData).toString();
        
        // Construct final URL
        const queryUrl = `${this.basePath}?${parametersString}`
 
        // Launch transition
        this.call('goTo', { url: queryUrl, transition: 'repertoire' }, 'Load')
    }
}
