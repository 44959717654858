import { module } from 'modujs';
import { html } from '../utils/environment';


const CLASS = {
    REVERSELOGO: `has-logo-reverse`,
    BLACKHEADER: `has-black-header`
}

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

    }

    reverseColor(obj){
        if (obj.way == "enter") {
            html.classList.add(CLASS.REVERSELOGO)
        } else if(obj.way == "exit") {
            html.classList.remove(CLASS.REVERSELOGO)
        }
    }

    blackColor(obj){
        if (obj.way == "enter") {
            html.classList.add(CLASS.BLACKHEADER)
        } else if(obj.way == "exit") {
            html.classList.remove(CLASS.BLACKHEADER)
        }
    }

    destroy() {
        html.classList.remove(CLASS.REVERSELOGO)
        html.classList.remove(CLASS.BLACKHEADER)
    }
}
