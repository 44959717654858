import { module } from 'modujs';

const ITEM_ACTIVE_CLASS = 'is-active';

export default class extends module {
    constructor(m) {
        super(m)

        // UI
        this.$inners = this.$('inner')

        // Data
        this.activeItem = null

        // Events
        this.events = {
            click: {
                toggler: 'onButtonClick'
            }
        }
    }

    ///////////////
    // Lifecyle
    ///////////////
    init() {
    }

    destroy() {
        super.destroy()
    }

    ///////////////
    // Callbacks
    ///////////////
    onButtonClick(e) {
        // Get matching item
        let item = this.parent('item', e.curTarget)

        // Toggle current item
        this.toggleItem(item)
    }

    ///////////////
    // Methods
    ///////////////
    toggleItem(item) {
        if(item.classList.contains(ITEM_ACTIVE_CLASS)) {
            this.closeItem(item)
        } else {
            this.openItem(item)
        }
    }

    openItem(item) {
        // If active item, close it
        if (this.activeItem) {
            this.closeItem(this.activeItem)
        }

        this.activeItem = item

        const toggler = this.$('toggler', item)[0]
        toggler.setAttribute('aria-expanded', true)
        toggler.setAttribute('aria-pressed', true)

        // Get the content el and open it w/ animation
        const inner = this.$('inner', item)[0]

        if (!inner) {
            return
        }

        const height = this.$('content', item)[0].clientHeight
        // inner.style.height = height + 'px'
        item.style.setProperty('--height', `${height}px`)

        // Add active clas on item for style & status
        item.classList.toggle(ITEM_ACTIVE_CLASS)
    }

    closeItem(item) {
        let toggler = this.$('toggler', item)[0]
        toggler.setAttribute('aria-expanded', false)
        toggler.setAttribute('aria-pressed', false)

        // Trigger close action only if currently active
        if(item.classList.contains(ITEM_ACTIVE_CLASS)) {
            // Get the content el and close it w/ animation
            let inner = this.$('inner', item)[0]

            if (!inner) return

            // inner.style.height = this.defaultHeight + 'px'

            // item.style.setProperty('--height', `${this.defaultHeight}px`)

            // Remove active clas on item for style & status
            item.classList.remove(ITEM_ACTIVE_CLASS);
        }
    }
}
