import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m)

        // Events
        this.events = {
            click: 'onClick'
        }
    }

    ///////////////
    // Lifecyle
    ///////////////
    init() {
    }

    destroy() {
        super.destroy()
    }

    ///////////////
    // Callbacks
    ///////////////
    onClick(e) {
        e.preventDefault()

        const href = e.currentTarget.href
        const $target = document.getElementById(href.split('#')[1])

        if ($target) this.call('scrollTo', { target: $target }, 'Scroll', 'main')

    }
}
