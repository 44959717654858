import { module as Module } from 'modujs'

export default class extends Module {
    constructor(m) {
        super(m)

        this.transition = this.getData('transition') || null

        this.events = {
            change: 'onChange'
        }
    }

    onChange(e) {
        e.preventDefault()
        
        const url = e.target.value
        if (!url) return

        this.call('goTo', { url: url, transition: this.transition }, 'Load')
    }
}
